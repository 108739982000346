.mainvisual {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.mainvisual::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainvisual__pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.mainvisual__desc {
  position: relative;
  z-index: 2;
}
.mainvisual__desc h2 {
  max-width: 580px;
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 40px;
  letter-spacing: 0.16em;
  word-break: break-all;
}
.mainvisual__desc span {
  width: 240px;
  height: 1px;
  display: block;
  margin-top: 16px;
  margin-bottom: 24px;
}
.mainvisual__desc p {
  max-width: 580px;
  font-size: 14px;
  letter-spacing: 0.1em;
  word-break: break-all;
}
.mainvisual__scroll {
  width: 64px;
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: calc(50% - 32px);
  bottom: -48px;
  z-index: 2;
}
.mainvisual__scroll span {
  width: 1px;
  height: 96px;
  background: #2e1f12;
  margin: 4px auto 0 auto;
  overflow: hidden;
  display: block;
  position: relative;
}
.mainvisual__scroll span::after {
  content: '';
  width: 1px;
  height: 32px;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  animation: scroll 1.6s linear infinite;
}
.main-content {
  margin-top: 48px;
}
.c-photo__main {
  position: relative;
  width: 100%;
  transform: translate3d(0px, 0, 0);
}
.c-photo__main__list {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
}
.c-photo__main__list__item {
  width: 100%;
  height: calc(100vh - 80px);
  background: #eee;
  flex-shrink: 0;
  transition: transform 500ms;
}
.c-photo__main__list__item img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.c-photo__main__prev,
.c-photo__main__next {
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  z-index: 1;
  background: #fff;
  background: var(--theme-content-section-background-color);
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 100%;
}
.c-photo__main__prev::after,
.c-photo__main__next::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid #0094cc;
  border-left: 2px solid var(--theme-primary-color);
  border-bottom: 2px solid #0094cc;
  border-bottom: 2px solid var(--theme-primary-color);
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
  left: 13px;
}
.pictures__all__photo__btn {
  z-index: 10;
  width: 56px;
  height: 56px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  position: absolute;
  top: calc(50% - 28px);
  cursor: pointer;
}
.pictures__all__photo__btn:hover {
  background: rgba(0, 0, 0, 0.8);
}
.pictures__all__photo__btn::after {
  content: '';
  width: 16px;
  height: 16px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  top: 18px;
  left: 22px;
  transform: rotate(-45deg);
  display: block;
}
.pictures__all__photo__btn.prev {
  left: 32px;
}
.pictures__all__photo__btn.next {
  right: 32px;
  transform: scale(-1, 1);
}
.desktop-indent {
  margin-left: 100px;
}
@keyframes scroll {
  0% {
    top: -32px;
  }
  100% {
    top: 100%;
  }
}
@media screen and (max-width: 768px) {
  .mainvisual {
    height: 360px;
    padding-bottom: 16px;
    align-items: flex-end;
  }
  .c-photo__main__list__item {
    height: 360px;
  }
  .mainvisual__desc {
  }
  .mainvisual__desc h2 {
    font-size: 24px;
  }
  .mainvisual__desc span {
    width: 120px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .mainvisual__desc p {
    font-size: 12px;
  }
  .mainvisual__scroll {
    display: none;
  }
  .main-content {
    margin-top: 0;
  }
  .pictures__all__photo__btn {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.6);
    top: calc(50% - 16px);
  }
  .pictures__all__photo__btn:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .pictures__all__photo__btn::after {
    width: 8px;
    height: 8px;
    top: 11px;
    left: 13px;
  }
  .pictures__all__photo__btn.prev {
    left: 8px;
  }
  .pictures__all__photo__btn.next {
    right: 8px;
  }
  .desktop-indent {
    margin-left: 0px;
  }
}
